<template>
  <div>
    <AppHeader title="预约列表"/>
    <Panel class="tabs">
      <template slot="title">
        <div :class="`tab${tabIndex===0?' active':''}`" @click="switchBookings(0)">已预约</div>
        <div :class="`tab${tabIndex===1?' active':''}`" @click="switchBookings(1)">已完成</div>
      </template>
      <div slot="content" v-infinite-scroll="loadBookings"
           infinite-scroll-disabled="loading"
           infinite-scroll-distance="10">
        <div class="line" v-for="(booking,idx) of bookings">


          <div class="flex-dir-row">


            <div class="flex-item-auto booking-title" v-if="booking.status=='实验中'||booking.status=='已预约'" @click="startexperiment(booking.id,booking.equipmentId)">
              <div class="blade">{{ booking.status }}</div>
              {{ booking.equipment }}
            </div>
            <div class="flex-item-auto booking-title" v-else>
              <div class="blade">{{ booking.status }}</div>
              {{ booking.equipment }}
            </div>


            <div v-if="booking.cancelable" class="booking-op-btn gradient-red" @click="cancelBooking(booking.id)">
              取消
            </div>
          </div>
          <div class="flex-dir-row mt5">
            <div class="flex-item-auto" style="color: #777">{{ booking.beginAt }} ～ {{ booking.endAt }}</div>
            <div class="booking-op-btn gradient-blue" @click="showGardAuth(booking.id)">
              门禁
            </div>
          </div>
        </div>
        <div class="line text-center" v-if="bookings.length===0">暂无记录</div>
      </div>
    </Panel>
    <div class="btn green mt10" v-if="tabIndex===0" @click="jumpToApply">立即预约</div>

    <mt-popup
        v-model="guardAuthVisible"
        position="bottom">
      <Panel class="tabs" :title="false" style="border:none">
        <div slot="content">
          <div class="line" v-for="(auth,idx) of guardAuths" v-if="guardAuthVisible">
            <div class="flex-dir-row">
              <label class="line-label">预约编号：</label>
              {{ auth.booking.code }}
            </div>
            <div class="flex-dir-row">
              <label class="line-label">时间段：</label>
              {{ dateFormat(auth.beginAt) }} ~ {{ dateFormat(auth.endAt) }}
            </div>
            <div class="flex-dir-row">
              <label class="line-label">房间：</label>
              {{ auth.room }}
            </div>
            <div class="flex-dir-row">
              <label class="line-label">下发时间：</label>
              {{ dateFormat(auth.downloadAt) || '--' }}
            </div>
          </div>
        </div>
      </Panel>
    </mt-popup>
  </div>
</template>

<style type="text/css">
.booking-op-btn {
  color: #fff;
  text-align: center;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 12px;
}

.booking-title {
  font-weight: 500;
  display: flex;
  align-items: center;
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {InfiniteScroll, MessageBox, Popup} from 'mint-ui';
import store from "@/plugins/store";

export default {
  components: {
    AppHeader,
    Panel,
    InfiniteScroll,
    MessageBox,
    Popup
  },
  data() {
    return {
      tabIndex: 0,
      page: 1,
      bookings: [],
      loading: false,
      noMore: false,
      guardAuthVisible: false,
      guardAuths: []
    }
  },
  methods: {
    jumpToApply() {
      this.$router.push({
        path: '/license',
        query: {
          tabIndex: 0
        }
      })
    },
    switchBookings(tabIndex) {
      this.tabIndex = tabIndex
      this.bookings = []
      this.page = 1
      this.noMore = false
      this.loadBookings()
    },
    loadBookings() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('orderByBeginAtDesc', 'true')
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      const nowTs = this.$moment().valueOf()
      if (this.tabIndex === 0) {
        data.append('intersect', 'false')
        data.append('beginAt', `${nowTs}`)
      } else {
        data.append('intersect', 'true')
        data.append('endAt', `${nowTs}`)
      }
      this.$http.post(`/ucenter/equip/queryBooking`, data).then(res => {
        if (res.data && res.data.length > 0) {
          /*res.data.map(b => {
            const beginAt = that.$moment(b.beginAt, "YYYY-MM-DD HH:mm").valueOf()
            //data[i].status == "已完成" || data[i].status == "被撤销" || (tmpdate - now) < 4 * 3600000
            b.cancelable = !(b.status === '已完成' || b.status === '被撤销' || beginAt - nowTs < 4 * 3600000)
            return b
          })*/

          that.bookings = that.bookings.concat(res.data)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },
    cancelBooking(id) {
      const that = this
      MessageBox.confirm('确定取消预约?').then(action => {
        that.$http.post(`/portal/booking/cancel?id=${id}`).then(res => {
          that.switchBookings(0)
        })
      })
    },
    startexperiment(bookingId,equipmentId){
      console.log("id"+equipmentId)
      const that = this;
      this.$http.post(`/api/booking/getCurrentBookingExperiment?bookingid=${bookingId}`).then(res => {
        if (res.success) {
          let booking = res.data
          console.log("clientOpen",booking.clientOpen)
          let equipmentId=booking.equipmentId
          console.log("booking",booking.equipmentId)
          if (booking.clientOpen){
            that.$router.push({
              path: '/exp',
              query: {
                equipmentId: equipmentId,
                bookingId: bookingId
              }
            })
          } else {
            store.commit('setCurBooking', booking)
            that.$router.push({
              path: '/confirm',
              query: {
                equipmentId: equipmentId,
                isNoBooking: false
              }

            });
          }
        } else {
          that.show = false
          that.prepared = false
          setTimeout(function (){
            that.show = true
          }, 500)
        }
      });
    },
    showGardAuth(id) {
      const that = this
      this.$http.post(`/api/booking/guards?id=${id}`).then(res => {
        console.log("res:"+res.data)
        that.guardAuths = res.data
        that.guardAuthVisible = true
      })
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return this.$moment(timestamp).format('YY-MM-DD HH:mm')
      }
      return ''
    }
  },
  mounted() {
    this.switchBookings(0)
  }
}
</script>
